// VueJS
import Vue from 'vue'

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as VueGoogleMaps from "vue2-google-maps";


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDShk5oEs_K24BhEjhgLYEJdhIaHZVD4cw",
    libraries: "places"
  },
  installComponents: true,
});

Vue.use(Antd);


// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

// Main application view
import App from './App.vue'

import 'primeicons/primeicons.css'

// Vue Router
import router from './router'

// App Styling
import './scss/app.scss';

Vue.config.productionTip = false

// Initialize Vue
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')