<template>
    <!-- Main Sidebar -->
    <a-layout-sider collapsible class="sider-primary" breakpoint="lg" collapsed-width="0" width="250px"
        :collapsed="sidebarCollapsed" @collapse="$emit('toggleSidebar', !sidebarCollapsed)" :trigger="null" :class="[
            'ant-layout-sider-' + sidebarColor,
            'ant-layout-sider-' + sidebarTheme,
        ]" theme="light" :style="{ backgroundColor: 'transparent' }">
        <div class="brand">
            <img src="images/logo-ct-black.png" alt="" /> <span>Muse Dashboard</span>
        </div>
        <hr />

        <!-- Sidebar Navigation Menu -->
        <a-menu theme="light" mode="inline" :open-keys="openKeys" @openChange="onOpenChange">
            <a-menu-item class="menu-item-header"> Content </a-menu-item>
            <a-menu-item>
                <router-link to="/admins/list">
                    <span class="label">Admins</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/reports/reports-list">
                    <span class="label">Reports</span>
                </router-link>
            </a-menu-item>

            <a-menu-item>
                <router-link to="/users/list">
                    <span class="label">Users</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/guides/guides-list">
                    <span class="label">Guides</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/places/places-list">
                    <span class="label">Places</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/cities/cities-list">
                    <span class="label">Cities</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/preferences/preferences-list">
                    <span class="label">Preferences</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/categories/categories-list">
                    <span class="label">Categories</span>
                </router-link>
            </a-menu-item>
            <a-menu-item>
                <router-link to="/subcategories/subcategories-list">
                    <span class="label">Subcategories</span>
                </router-link>
            </a-menu-item>
        </a-menu>
    </a-layout-sider>
    <!-- / Main Sidebar -->
</template>

<script>
import VueCookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode'
export default {
    props: {
        // Sidebar collapsed status.
        sidebarCollapsed: {
            type: Boolean,
            default: false,
        },

        // Main sidebar color.
        sidebarColor: {
            type: String,
            default: "primary",
        },

        // Main sidebar theme : light, white, dark.
        sidebarTheme: {
            type: String,
            default: "light",
        },
    },
    data() {
        return {
            rootSubmenuKeys: [
                "dashboards",
                "pages",
                "applications",
                "ecommerce",
                "authentication",
                "basic",
                "components",
                "changelog",
            ],
            openKeys: this.$route.meta.sidebarMap,
        };
    },
    methods: {
        onOpenChange(openKeys) {
            const latestOpenKey = openKeys.find(
                (key) => this.openKeys.indexOf(key) === -1
            );

            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys;
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        },
    },
};
</script>
